.rc-box{
  flex: 1;
  display: flex;
  flex-direction: column;
  .content{
    box-sizing: border-box;
    padding: 22px 25px;
    margin-top: 10px;
    flex: 1;
    background: url("../../assets/right-bg.png") no-repeat;
    background-size:100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .legend{
      box-sizing: border-box;
      border-bottom: 1px solid  #2441A9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item{
        display: flex;
        align-items: center;
        span{
          display: inline-block;
        }
        .icon{
          width: 20px;
          height: 10px;
          background: #15D8FE;
          border-radius: 2px;
          &.newIcon{
            background: rgba(255,255,255,0);
            border: 1px solid #15D8FE;
          }
        }
        .desc{
          margin-left: 9px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 36px;
        }
        .total{
          margin-left: 9px;
          font-size: 20px;
          font-family: Arial;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 36px;
        }
      }
    }
    .barWrapper{
      padding-top: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item{
        p{
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #9CDDFF;
        }
        .barBox{
          display: flex;
          align-items: center;
          .totalresource{
            width: 45px;
            font-size: 16px;
            color: #26B3FF;
            text-align: left;
          }
          .bar{
            height: 9px;
            flex: 1;
            background: #021864;
            border: 2px solid #15D8FE;
            margin: 0 10px;
            box-sizing: border-box;
            position: relative;
            .innerbar{
              position: absolute;
              left: 0%;
              display: inline-block;
              background:#15D8FE ;
              height: 100%;
              width: 50%;
            }
          }
          .new{
            width: 45px;
            font-size: 16px;
            color: #26B3FF;
            text-align: right;
          }
        }
      }      
    }
  }  
}
