.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: url(../../assets/bg.png) no-repeat center / 100% 100%;
  &-container {
    display: flex;
    flex: 1;
    padding: 70px 24px 24px;
    overflow-y: hidden;
  }
}