* {
  box-sizing: border-box!important;
}

body {
  font-size: 14px;
  overflow: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}


@font-face{
  font-family: 'DIN';
  src: url('./font/bb3347.ttf');
}
@font-face{
  font-family: 'QuartzRegular';
  src: url('./font/QuartzRegular.ttf');
}
@font-face {  
  font-family: 'LcdD';  
  src: url('./font/LcdD.ttf');
}
@font-face {  
  font-family: 'alpht';  
  src: url('./font/Alibaba-PuHuiTi-Regular.woff2');
}
*::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: #a5a5a5;
}

*::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 0;
  background: #eeeeee;
}