.r-box{
  flex: 1;
  display: flex;
  flex-direction: column;
  .content{
    box-sizing: border-box;
    padding: 22px 25px;
    margin-top: 10px;
    flex: 1;
    background: url("../../assets/right-bg.png") no-repeat;
    background-size:100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .item{
      .desc{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: #9CDDFF;
      }
      .barbox{
        margin-top: 10px;
        height: 6px;
        background: rgba(34, 183, 226,0.2);
        .bar{
          height: 100%;
        }
      }
    }
  }  
}
