.app-title {
  position: fixed;
  top: 0;
  height: 84px;
  width: 100%;
  background: url("../../assets/h-bg.png") no-repeat center / 1920px 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icon{
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../../assets/header-icon-clock.png') no-repeat center / 100% 100%;
  }
  .text{
    font-size: 16px;
    color: #0A9CEA;
    line-height: 20px;
    margin-left: 7px;
  }
  .common{
    display: flex;
    align-items: flex-start;
    padding-top: 13px;
    flex: 1;
    height: 100%;
  }
  .left{
    padding-left: 40px;
    a {
      text-decoration: none;
    }
  }
  .right{
    padding-right: 48px;
    justify-content: flex-end;
  }
}
.loginOut{
  margin-left: 80px;
  padding: 5px 10px;
  background: #279aff;
  border-radius: 10px;
  font-family: "alpht";
  text-align: center;
  font-size: 14px;
  margin-top: -5px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
}
.loginOut:active{
  opacity: 0.8;
}
