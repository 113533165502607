.c-title{
  display: flex;
  align-items: center;
  .icon{
    display:inline-block;
    width:24px;
    height:19px;
    background: url("../../assets/boxtitle.png") no-repeat;
    background-size: 100% 100%;
  }
  .text{
    margin-left: 10px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
  }
}