.cb-box {
  height: 224px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  pointer-events: none;
  .item {
    width: 100%;
    height: 107px;
    display: flex;
    justify-content: space-between;
    &:first-child {
      margin-bottom: 10px;
    }
    .card {
      width: 220px;
      height: 107px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 72px;
      pointer-events: fill;
      .info {
        pointer-events: none;
        .num {
          font-size: 22px;
          font-family: Arial;
          font-weight: bold;
          color: #ffffff;
          pointer-events: none;
        }
        .precent {
          padding-left: 11px;
          font-size: 14px;
          font-family: Arial;
          font-weight: 400;
          color: #ffffff;
          pointer-events: none;
        }
      }
      .desc {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 16px !important;
        pointer-events: none;
      }
      &.t1 {
        background: url("./icons/t1.png") no-repeat;
        background-size: 100% 100%;
      }
      &.t2 {
        background: url("./icons/t2.png") no-repeat;
        background-size: 100% 100%;
      }
      &.t3 {
        background: url("./icons/t3.png") no-repeat;
        background-size: 100% 100%;
      }
      &.t4 {
        background: url("./icons/t4.png") no-repeat;
        background-size: 100% 100%;
      }
      &.b1 {
        background: url("./icons/b1.png") no-repeat;
        background-size: 100% 100%;
      }
      &.b2 {
        background: url("./icons/b2.png") no-repeat;
        background-size: 100% 100%;
      }
      &.b3 {
        background: url("./icons/b3.png") no-repeat;
        background-size: 100% 100%;
      }
      &.b4 {
        background: url("./icons/b4.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
.card :hover {
  cursor: pointer;
}
.detail {
  position: absolute;
  left: 0;
  z-index: 1000;
  padding: 10px;
  line-height: 21px;
  background-color: rgba(50, 50, 50, 0.7);
  border-radius: 4px;
  color: #f4e925;
  display: none;
  pointer-events: none;
  .title {
    color: white;
  }
}
.barBox {
  display: flex;
}
.dataIndex {
  padding: 0 0 0 5px;
}
.count {
  padding: 0 8px 0 5px;
}

