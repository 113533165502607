.index-map {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 24px;
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    .item {
      position: relative;
      margin-top: 15px;
      width: 317px;
      height: 64px;
      background: url("../../assets/map-num-bg.png") no-repeat center / 100%
        100%;
      font-family: "LcdD";
      color: #f6ed03;
      font-size: 56px;
      font-weight: normal;
      line-height: 64px;
      text-align: center;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 21px;
        height: 63px;
        background: url("../../assets/map-num-left.png") no-repeat center / 100%
          100%;
      }
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        content: "";
        width: 21px;
        height: 63px;
        background: url("../../assets/map-num-right.png") no-repeat center /
          100% 100%;
      }
    }
  }
  .echart-back {
    position: absolute;
    right: 0;
    top: 20px;
    &:hover {
      cursor: pointer;
    }
  }
  .echarts-wrap {
    pointer-events: auto;
    padding-bottom: 24px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .echart-box {
    position: relative;
    flex: 1;
    width: 100%;
  }
  .echart-region-name {
    position: absolute;
    right: 0;
    bottom: 20px;
    line-height: 28px;
    font-size: 16px;
    color: #f3f3f3;
  }
  .level-list {
    position: absolute;
    top: 52px;
    left: 0;
    color: #fff;
    // .level_img{
    //   display: inline;
    .flex {
      display: flex;
    }
    // }
    .level {
      margin-bottom: 12px;
      width: 91px;
      margin-right: 5px;
      height: 37px;
      line-height: 37px;
      text-align: center;
      font-size: 16px;
      color: #9cddff;
      cursor: pointer;
      background: url("../../assets/level-item-bg.png") no-repeat center / 100%
        100%;
      // &.active {
      //   background: url('../../assets/level-item-active-bg.png') no-repeat center / 100% 100%;
      // }
    }
    .active {
      margin-bottom: 12px;
      width: 91px;
      margin-right: 5px;
      height: 37px;
      line-height: 37px;
      text-align: center;
      font-size: 16px;
      color: #9cddff;
      cursor: pointer;
      background: url("../../assets/level-item-active-bg.png") no-repeat center /
        100% 100%;
    }
  }
}
.cityName{
  color: white;
  font-size: 16px;
  margin-bottom: 5px;
}
