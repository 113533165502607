.real-monitor {
  flex: 1;
  display: flex;
  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .center {
    margin: 0 36px;
    flex: 2;
    display: flex;
    flex-direction: column;
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}