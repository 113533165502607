// .b-box{
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   .content{
//     box-sizing: border-box;
//     padding: 22px 25px;
//     margin-top: 10px;
//     flex: 1;
//     background: url("../../assets/left-top-bg.png") no-repeat;
//     background-size:100% 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     .item{
//       display: flex;
//       justify-content: flex-start;
//       .icon{
//         width: 40px;
//         height: 40px;
//       }
//       .type{
//         width: 100%;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         box-sizing: border-box;
//         height: 40px;
//         padding-left: 16px;
//         padding-top: 6px;
//         padding-bottom: 6px;
//         p{
//           width: 100%;
//           line-height: 16px;
//           .index{
//             font-size: 16px;
//             font-family: Arial;
//             font-weight: 400;
//             font-style: italic;
//             color: #26B3FF;
//           }
//           .text{
//             margin-left: 15px;
//             font-size: 16px;
//             font-family: Microsoft YaHei;
//             font-weight: 400;
//             color: #9CDDFF;
//           }
//         }
//         .line{
//           margin-top: 10px;
//           display: inline-block;
//           height: 1px;
//           background: rgba(36, 179, 255,0.3);
//           width: 100%;
//           position: relative;
//           &::after{
//             content: "";
//             position: absolute;
//             width: 9px;
//             height: 3px;
//             background: #24B3FF;
//             right: 0;
//             bottom: -1px;
//           }
//         }
//       }
//     }
//   }  
// }
// .item :hover{
//   cursor: pointer;
// }
.r-box{
  width: 100%;
  
  flex: 1;
  display: flex;
  flex-direction: column;
  .content{
    box-sizing: border-box;
    padding: 22px 25px;
    margin-top: 10px;
    flex: 1;
    background: url("../../assets/right-bg.png") no-repeat;
    background-size:100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // .item{
    //   .desc{
    //     display: flex;
    //     justify-content: space-between;
    //     font-size: 16px;
    //     color: #9CDDFF;
    //   }
    //   .barbox{
    //     margin-top: 10px;
    //     height: 6px;
    //     background: rgba(34, 183, 226,0.2);
    //     .bar{
    //       height: 100%;
    //     }
    //   }
    // }
  }  
}

