.login-page {
  width: 1920px;
  height: 1080px;
  background: url("../../assets/login-bg.png") no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-title {
  width: 519px;
  height: 47px;
  background: url("../../assets/login-title.png") no-repeat;
  position: absolute;
  left: 50%;
  top: 21%;
  transform: translate(-50%, 0%);
}
.login-box {
  width: 600px;
  height: 400px;
  .min-title {
    font-family: "alpht";
    font-size: 32px;
    color: #d5eeff;
    line-height: 45px;
    letter-spacing: 1px;
    text-align: left;
    font-style: normal;
    text-align: center;
  }
  .form-item {
    width: 442px;
    height: 50px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    border: 1px solid #146ba8;
    margin-top: 30px;
    position: relative;
    .icon {
      width: 22px;
      height: 25px;
      display: block;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translate(0, -50%);
    }
    .icon:before {
      content: "";
      display: block;
      width: 1px;
      height: 26px;
      background: #1da9ee;
      opacity: 0.5;
      position: absolute;
      right: -20px;
    }
    .user {
      background: url("../../assets/user-icon.png") no-repeat;
      background-size: contain;
    }
    .pwd {
      background: url("../../assets/password-icon.png") no-repeat;
      background-size: contain;
    }
    .code {
      background: url("../../assets/code-icon.png") no-repeat;
      background-size: contain;
    }
    .code-img {
      height: 48px;
      width: 160px;
      position: absolute;
      top: 0px;
      right: 0px;
      border-radius: 10px;
      cursor: pointer;
      border: none;
      background: #175898;
      color: white;
      img {
        height: 48px;
        width: 160px;
        border-radius: 10px;
      }
    }
    .eye-icon {
      width: 21px;
      height: 22px;
      display: block;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
    }
    .eye-icon:active {
      opacity: 0.8;
    }

    .open {
      background: url("../../assets/eye-open.png") no-repeat;
    }
    .close {
      background: url("../../assets/eye-close.png") no-repeat;
    }
    .tip {
      color: #f81d22;
      padding-left: 80px;
      padding-top: 5px;
      font-size: 12px;
    }
    .tip.hide {
      display: none;
    }
  }
  .pwd-item {
    width: 442px;
    height: 50px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    border: 1px solid #146ba8;
    margin-top: 30px;
  }
  .input-item {
    width: 442px;
    height: 50px;
    background: rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    border: 1px solid #146ba8;
    outline-style: none;
    font-family: "alpht";
    padding-left: 80px;
    padding-right: 80px;
    color: #2a91d9;
    font-size: 18px;
  }
  .input-item:active,
  .input-item:focus,
  .input-item:focus-visible {
    border: 1px solid #146ba8;
  }
}

.login-btn {
  width: 442px;
  height: 60px;
  background: #279aff;
  border-radius: 10px;
  font-family: "alpht";
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 30px;
  cursor: pointer;
  user-select: none;
}
.login-btn:active {
  opacity: 0.8;
}
::-webkit-input-placeholder {
  /* WebKit browsers，webkit内核浏览器 */
  color: #2a91d980;
  font-family: "alpht";
  font-size: 16px;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #2a91d980;
  font-family: "alpht";
  font-size: 16px;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #2a91d980;
  font-size: 16px;
  font-family: "alpht";
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #2a91d980;
  font-family: "alpht";
  font-size: 16px;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../assets/login-bg-loading.png");
  z-index: 999;
}
.loading-icon {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login-type-box {
  width: 400px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  // margin-top: 40px;
  padding-bottom: 20px;
  border-bottom: 2px solid #d9e9f0;

  .type-item {
    font-size: 22px;
    color: #a9c4fd;
    width: 33%;
    text-align: center;
    font-family: "pingfangblod";
    cursor: pointer;
  }

  .type-item.active {
    color: #3576fb;
    position: relative;
  }

  .type-item.active:before {
    content: "";
    width: 100%;
    height: 11px;
    display: block;
    position: absolute;
    bottom: -22px;
    background: url("../../assets/underline.png") 50% 50%;
  }
}
