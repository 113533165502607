.rc-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  .content {
    box-sizing: border-box;
    padding: 22px 25px;
    margin-top: 10px;
    flex: 1;
    background: url("../../assets/right-bg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top-legend {
      box-sizing: border-box;
      border-bottom: 1px solid #2441a9;
      display: flex;
      align-items: center;
      justify-content: center;
      .item {
        display: flex;
        align-items: center;
        span {
          display: inline-block;
        }
        .icon {
          width: 20px;
          height: 10px;
          background: #15d8fe;
          border-radius: 2px;
          &.newIcon {
            background: rgba(255, 255, 255, 0);
            border: 1px solid #15d8fe;
          }
        }
        .desc {
          margin-left: 9px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 36px;
        }
        .total {
          margin-left: 9px;
          font-size: 20px;
          font-family: Arial;
          font-weight: 400;
          color: #ffffff;
          line-height: 36px;
        }
      }
    }
    .barWrapper {
      padding-top: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item {
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #9cddff;
        }
        .barBox {
          display: flex;
          align-items: center;
          .totalresource {
            width: 45px;
            font-size: 16px;
            color: #26b3ff;
            text-align: left;
          }
          .bar {
            height: 9px;
            flex: 1;
            background: #021864;
            border: 2px solid #15d8fe;
            margin: 0 10px;
            box-sizing: border-box;
            position: relative;
            .innerbar {
              position: absolute;
              left: 0%;
              display: inline-block;
              background: #15d8fe;
              height: 100%;
              width: 0;
              transition: width 0.5s;
            }
          }
          .new {
            width: 45px;
            font-size: 16px;
            color: #26b3ff;
            text-align: right;
          }
        }
      }
    }
  }
}
.item :hover {
  cursor: pointer;
}
.resource_detail {
  position: absolute;
  right: 30px;
  top: 50%;
  z-index: 9999;
  width: 10%;
  padding: 10px;
  line-height: 21px;
  background-color: rgba(50, 50, 50, 0.7);
  border-radius: 4px;
  color: white;
  display: none;
  pointer-events: none;
}
.resource_detail_box {
  margin-bottom: 8px;
}
.resource_detail_data {
  display: flex;
}
.resource_detail_data_center {
  display: flex;
  margin: 8px 0;
}
.total_count1 {
  color: #f4e925;
}
